import React from "react"

import { Spin } from "antd"

import { Container } from "../"

export const SpinnerPanel = (props) => <Container style= {{ marginTop: "12px", display: "flex", alignItems:"center" ,justifyContent: "center",width: "100%", height: "100%"}}>
    <Spin {...props}/>
</Container>

export default Spin;