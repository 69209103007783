import React from "react"
import { Container, Logo, Menu } from "../../components"
import { Link, useStaticQuery, graphql } from "gatsby"
import css from "./style.module.scss"



const links = [
  {
    name:"About",
    url:"/"
  },
  {
    name:"Help",
    url:"/"
  },
]

const Header = (props) => { 
  const { data } = props;

  return (
  <header className={css.mainContainer}>

    <Link to="/" className={css.logoContainer}>
      <Logo/>
    </Link>

    <Container className={css.agentName}>
            {data && data.site.siteMetadata.agent.name}
        </Container>
    <Container className={css.menuContainer}>

    </Container>
    <Menu links={links}/>
  </header>
)}

export default Header
