import React, { useState } from "react"
import axios from "axios";
import { Button, Container, WomanOutlined, ManOutlined, SelectOption, Select, TextInput } from "../../../.."
import css from "./style.module.scss";

const handleInputChange = event => {
    const target = event.target
    const value = target.value
    return value;
}

export default (props) => {

    const [gender, setGender] = useState(props.gender);
    const [age, setAge] = useState(props.age);
    const [zip_code, setZipCode] = useState(props.zip_code)
    const [county, setCounty] = useState(props.county)
    const [avaiableCounties, setAvaiableCounties] = useState(props.avaiableCounties)
    const [income, setIncome] = useState(props.income);

    const zipCodeChage = async (event) => {


        setZipCode(handleInputChange(event))
        if (!(event.target && event.target.value && event.target.value.length === 5)) {
            setAvaiableCounties([])
            setCounty(null);
            return;
        }

        const response = await axios({
            method: "get",
            url: `https://api.qa.apolloquotes.com/check-zip-code/${event.target.value}`,
        })


        if (response.status == 200 && response.data.status != "400" && response.data.counties.length > 0) {

            const { counties } = response.data;
            setCounty(counties[0])
            setAvaiableCounties(counties)
        } else {
            setAvaiableCounties([])
            setCounty(null);
        }

    }

    return <Container className={css.userInputs}>

        <Container className={css.userInputsTitle}>
            Profile
        </Container>

        <Container className={`${css.genderInput}`}>

        </Container>


        <Container className={css.inputContainer}>
            <Container className={css.inputLabel}>
                Gender
            </Container>
            <Container style={{ display: "flex", flexGrow: 1 }}>
                <Select value={gender} allowClear showArrow={!gender} onChange={setGender} dropdownStyle={{ background: "#fff" }}>
                    <SelectOption className={`${css.bgWhite}`} value="Male">
                        <Container className={`${css.genderInputMaleContainer} ${gender === "Male" ? css.maleActive : ""}`}>
                            Male
                    <ManOutlined />
                        </Container>
                    </SelectOption>
                    <SelectOption className={css.bgWhite} value="Female">
                        <Container className={`${css.genderInputFemaleContainer} ${gender === "Female" ? css.femaleActive : ""}`}>
                            Female
                    <WomanOutlined />
                        </Container>
                    </SelectOption>
                </Select>
            </Container>
        </Container>

        <Container className={css.inputContainer}>
            <Container className={css.inputLabel}>
                Zip Code
            </Container>
            <Container className={css.inputText}><TextInput onChange={zipCodeChage} value={zip_code} style={{ height: "30px" }} allowClear /></Container>
        </Container>

        {
            avaiableCounties && avaiableCounties.length > 0 && <Container className={css.inputContainer}>
                <Container className={css.inputLabel}>
                    County
            </Container>
                <Select size="large" options={
                    avaiableCounties.map(a => {
                        return {
                            label: a.name,
                            value: a.name,
                        }
                    })}

                    value={county ? county.name : null}

                    defaultValue={avaiableCounties[0].name}

                    onChange={(v) => {
                        setCounty(avaiableCounties.filter(f => f.name == v)[0])
                    }} />

            </Container>

        }
        <Container className={css.inputContainer}>
            <Container className={css.inputLabel}>

                Age
            </Container>
            <Container className={css.inputText}><TextInput value={age} onChange={(event) => { setAge(handleInputChange(event)) }} style={{ height: "30px" }} allowClear /></Container>
        </Container>



        <Container className={css.inputContainer}>
            <Container className={css.inputLabel}>
                Income
            </Container>
            <Container className={css.inputText}><TextInput value={income} onChange={(event) => { setIncome(handleInputChange(event)) }} style={{ height: "30px" }} allowClear /></Container>
        </Container>
        <Container className={css.inputContainer}>
            <Container className={css.inputLabel}>
                Tobacco
            </Container>
            <Select options={
                [{
                    label: "yes",
                    value: "yes",
                },
                {
                    label: "no",
                    value: "no",
                }]} />

        </Container>

        <Container style={{ margin: "0px auto", display: "flex", width: "78px" }}>


            {county && age ?
                <Button shape="round" onClick={() => {
                    props.onSubmit && props.onSubmit({
                        age,
                        gender,
                        income,
                        zip_code,
                        fips_code: county.fips,
                        state: county.state,
                    })
                }} type="primary">
                    Submit
                </Button> :
                <Button disabled style={{ opacity: 0.7, background: "#1B75BC", color: "#fff" }} shape="round" onClick={() => { }} type="primary">
                    Submit
                </Button>}
        </Container>
    </Container>
}