export default [
    {
        id: "MARKETPLACE",
        name: "Market Place",
        inputs: [
            { name: "age", required: true, type: "number" },
            { name: "zip_code", required: true, type: "number" },
            { name: "fips_code", required: false, type: "number" },
            { name: "state", required: false },
            { name: "gender", required: true },
            { name: "income", required: true, type: "number" },

        ]
    },
    {
        name: "National General",
        id: "NATIONAL_GENERAL",
        inputs: [
            { name: "age", required: true, type: "number" },
            { name: "zip_code", required: true, type: "number" },
            { name: "fips_code", required: false, type: "number" },
            { name: "state", required: false },
            { name: "gender", required: true },
            { name: "income", required: true, type: "number" },

        ]
    },
    {
        name: "One Share",
        id: "ONE_SHARE",
        inputs: [
            { name: "age", required: true, type: "number" },
            { name: "zip_code", required: true, type: "number" },
            { name: "fips_code", required: false, type: "number" },
            { name: "state", required: false },
            { name: "gender", required: true },
            { name: "income", required: true, type: "number" },

        ]
    }
]