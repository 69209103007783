import templates from "./templates"
import html2canvas from "html2canvas";
import QRCode from 'qrcode';

export async function exportAsImage(name: string, extension: "jpg" | "png", element) {
    document.body.appendChild(element)


    const qrCodes = document.getElementsByClassName("replace-with-qr-code");

    for (let qrCode of qrCodes) {
        QRCode.toCanvas(qrCode, "jordan.myaspirequotes.com", {
            width: "60px",
            quality: 1
        }, function (error) {
            if (error) console.error(error)
        })
    }


    const canvas = await html2canvas(element, {
        scale: 3
    });

    document.body.appendChild(canvas);
    const uri = canvas.toDataURL(`image/${extension == "jpg" ? "jpeg" : extension}`);

    const a = document.createElement("a");
    a.href = uri;
    a.download = `${name}.${extension}`
    a.click();

    document.body.removeChild(canvas);
    document.body.removeChild(element);
}

export default {
    exportImage: exportAsImage,
    templates
}