import React from "react";
import { Container } from "../../"
import { ApolloCollors } from '../../../rules/design/apollo-colors'
import css from "./style.module.scss";
interface H1Interface {
    color: keyof typeof ApolloCollors,
    className?: string;
    children: any;
}

export default (props: H1Interface) => {
    return <Container className={ css.mainContainer + " " + props.className } style ={{ color: ApolloCollors[props.color]}}>
        {props.children}
    </Container>
}