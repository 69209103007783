import React, { useState} from "react";

import { Container , DownOutlined , UpOutlined} from '../'

import css from "./style.module.scss";

export default (props) => {


const [collapsed,setCollapsed] = useState(false);

return <Container className={ css.accordeonContainer}>
    <Container onPress={ () => { setCollapsed(!collapsed)}} className={css.accordeonHeader}>
        <Container>
            {props.title}
        </Container>
        <Container>
            {!collapsed ? <DownOutlined/> : <UpOutlined/>}
        </Container>
    </Container>
    {!collapsed && props.children}
</Container>}