import React, { useState } from "react";
import { Badge, Checkbox, Container, MinMaxSlider } from "../../../../"
import css from "./style.module.scss"
import { v4 as uuidv4 } from 'uuid';


function InnerGroup(props) {

}

export default (props) => {

    const { label, values, style , group_type } = props;


    return <Container className={css.filterGroupContainer} style={style}>
        <Container className={css.title}>
            {label}
        </Container>
        <Container className={`${ group_type === "MIN_MAX_SLIDERS" ? css.sliders : css.items}`}>
            {
                values.map(item => {
                    if (item.type == "MIN_MAX_SLIDER") {
                        return <MinMaxSlider key={uuidv4()} {...item} />
                    } else if(item.type == "CHECKBOX") {
                        return  <Container className={`${css.flex} ${css.checkBoxLabel}`} key={css.items + item.label}>
                            <Checkbox  onChange={(value) => {props.onChange && props.onChange( { label:item.label, value: value.target.checked}) }} /> 
                            <Container className={`${css.flex} ${css.checkBoxLabel}`}>
                                {item.label} { item.badge && <Badge style= {{ height:"24px", padding: "2px", marginLeft: "4px", marginTop: "-1px" }} color={item.badge.color} />}
                            </Container>
                        </Container>
                    }
                })
            }
        </Container>
    </Container>
}
