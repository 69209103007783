const ApolloCollors = {
    blue: "#1B75BC",
    gray: "#A8A8A8",
    Bronze: "#6A3805",
    Catastrophic: "#575988",
    Gold: "#AF9500",
    Platinum: "#3B3B41",
    Silver: "#B4B4B4"
}

export {
    ApolloCollors
}
