import React from "react";
import ReactDOM from "react-dom";

export const render = (element, node) => {
    return new Promise((resolve, reject) => {
        try {
            ReactDOM.render(element, node, () => {
                resolve();
            })
        } catch (e) {
            reject(e)
        }
    })
}

export default { 
    render
}