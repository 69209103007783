import React from "react";
import { Link } from "gatsby";
import { Container, Icon, WhatsAppOutlined, CloseOutlined , MenuOutlined  } from "../../components";
import { useStaticQuery, graphql } from "gatsby"
import css from "./style.module.scss";

export default 
    (props) => {
        const data = useStaticQuery(graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              agent {
                  whatsapp,
              }
            }
          }
        }
      `)

        return null;
      
        return (
            <Container className={css.mainContainer}>
                <Container className={css.whatsapp}>
                    <WhatsAppOutlined className={css.wppIcon} />
                    { data.site.siteMetadata?.agent?.whatsapp || "913-279-0077" }
                </Container>
                <nav className={css.menuNav}>
                    <ul>
                        {
                            props.links.map(link => (
                            <li key={link.name}>
                                <Link to={link.url}>
                                    {link.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
                <Icon component={props.open ? CloseOutlined : MenuOutlined} className={css.menuIcon} />
            </Container>

        )
    }
