import React from "react"

import templates from "./templates"
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import QRCode from 'qrcode';
import { PDFDocument } from 'pdf-lib'


export async function exportAsPDF(elements) {
    const mergedPdf = await PDFDocument.create();

    for (let element of elements) {

        const node = document.createElement("div");
        node.innerHTML = element;

        document.body.appendChild(node)

        const qrCodes = document.getElementsByClassName("replace-with-qr-code");

        for (let qrCode of qrCodes) {
            QRCode.toCanvas(qrCode, "jordan.myaspirequotes.com", {
                width: "60px",
                quality: 1
            }, function (error) {
                if (error) console.error(error)
            })
        }

        const canvas = await html2canvas(document.getElementById("rendered-pdf"), {
            scale: 3
        });

        const image = canvas.toDataURL("image/jpeg");

        const pdf = new jsPDF("p", "px", [842, 595]);
        pdf.addImage(image, "JPEG", 0, 0, 595, 842)

        const convertedPdf = await PDFDocument.load(await pdf.output("arraybuffer"));
        const pages = await mergedPdf.copyPages(convertedPdf, [0]);

        mergedPdf.addPage(pages[0])

        document.body.removeChild(node);
    }


    const pdfUri = await mergedPdf.saveAsBase64({ dataUri: true })

    const a = document.createElement("a");
    a.href = pdfUri;
    a.download = "Quotes.pdf"
    a.click();
}

export default {
    exportAsPDF,
    templates
}