import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid"
import _ from "lodash";

import axios from "axios";
import css from './style.module.scss'
import { Tabs } from 'antd';
import { Container } from "../";
import { FilterOutlined, IdcardOutlined, UserOutlined } from "../"
import { OfferCardList, QuotesPanelProfile, SearchFilters, UserInputs } from "./components"

import datasourcesmock from "./temp/datasources"

const { TabPane } = Tabs;

export default (props) => {
    try {
        (function (history: any) {
            var pushState = history.pushState;
            history.pushState = function (state) {
                if (typeof history.onpushstate == "function") {
                    history.onpushstate({ state: state });
                }

                return pushState.apply(history, arguments);
            };
        })(window.history);
    } catch (e) {

    }

    const siteData = props.data;

    let getParams = () => {
        try {
            return document.location.search.substring(1).split("&").reduce((old, current) => { const [key, value] = current.split("="); value && (old[key] = value.replace("%20", " ")); return old }, {});
        } catch (e) {
            return {}
        }
    }

    const [results, setResults]: any[] = useState()
    const [mobileActiveContainer, setMobileActiveContainer] = useState("results")
    const [activeTab, setActiveTab] = useState(`tab-${0}`)
    const [state, setNewState] = useState({ error: false, lastParams: null, loading: true, lastResults: null, params: getParams() });
    const [error, setError] = useState(false);
    const [datasources] = useState(datasourcesmock)

    const fetchQuotes = async (attributes) => {
        let r = [];

        const propKeys = Object.keys(attributes).filter(f => datasources[1].inputs.map(m => m.name).includes(f))
        let data: any = {};

        propKeys.map(pk => {
            const type = datasources[1].inputs.filter(f => f.name == pk)[0]
            data[pk] = type.type === "number" ? Number(attributes[pk]) : attributes[pk]
        })

        data.email = "changerequest@apollo.com"

        try {
            const response = await axios({
                method: 'post',
                url: `https://api.qa.apolloquotes.com/search-plan/`,
                data
            })

            const results = response.data.plans.results.map(r => { return { ...r, id: uuid() } });

            const MARKETPLACE = results.filter(f => f.source === "MARKETPLACE");
            const NATIONAL_GENERAL = results.filter(f => f.source === "NATIONAL_GENERAL");
            const ONE_SHARE = results.filter(f => f.source === "ONE_SHARE");

            response.data.plans = {
                MARKETPLACE,
                NATIONAL_GENERAL,
                ONE_SHARE,
                ALL: results
            }

            r.push({ ...datasources[0], response })

            return r;
        } catch (error) {
            setError(true);
            return { error: true }
        }
    }

    try {
        window.onpopstate = (window as any).onpushstate = (e) => {
            setResults();
            setError(false);
            const params = getParams();
            console.log("searchParams", params);

            setNewState({
                params,
                loading: true,
                error: false,
                lastParams: state.lastParams,
                lastResults: results
            })
        };
    } catch (e) {

    }
    useEffect(() => {
        const params = document.location.search.substring(1).split("&").reduce((old, current) => { const [key, value] = current.split("="); value && (old[key] = value.replace("%20", " ")); return old }, {})

        console.log("searchParams", params);

        fetchQuotes(params).then((r: any) => {
            if (r && r.error) {
                return;
            }
            if (!_.isEqual(r, results)) {
                setResults(
                    r[0].response.data,
                )
            }
        })
    }, [state])

    return (
        <>
            {
                error && <Container style={{ display: "flex", color: "red", height: "200px", width: "100%", justifyContent: "center", alignItems: "center" }}>
                    Ops, there was an error. Please change the data
                </Container>
            }
            <Container className={css.mainSearchContainer}>
                {
                    !error && <Container className={`${mobileActiveContainer !== "filters" ? "hidden-on-mobile" : ""}`}>
                        {results && <SearchFilters data={results.facets} />}
                    </Container>
                }
                {
                    !error && <Container className={`${css.cardListContainer} ${mobileActiveContainer !== "results" ? "hidden-on-mobile" : ""}`}>
                        <Tabs tabBarStyle={{ background: "#fff", paddingLeft: "24px", paddingRight: "24px" }} onChange={setActiveTab} activeKey={activeTab} centered  >
                            <TabPane tab={`Marketplace ${results ? "(" + results.plans["MARKETPLACE"].length + ")" : ""}`} key={`tab-${"0"}`}>
                                {<OfferCardList data={siteData} key={uuid()} loading={!results} userData={
                                    [
                                        {
                                            label: "age",
                                            value: props.age
                                        },
                                        {
                                            label: "zip code",
                                            value: props.zip_code
                                        }
                                    ]
                                }

                                    items={results ? results.plans["MARKETPLACE"] : []}

                                />}
                            </TabPane>
                            <TabPane tab={`National General ${results ? "(" + results.plans["NATIONAL_GENERAL"].length + ")" : ""}`} key={`tab-${"1"}`}>
                                {results && <OfferCardList data={siteData} key={uuid()} loading={false} userData={
                                    [
                                        {
                                            label: "age",
                                            value: props.age
                                        },
                                        {
                                            label: "zip code",
                                            value: props.zip_code
                                        }
                                    ]
                                }

                                    items={results.plans["NATIONAL_GENERAL"]}
                                />}
                            </TabPane>
                            <TabPane tab={`One Share ${results ? "(" + results.plans["ONE_SHARE"].length + ")" : ""}`} key={`tab-${"2"}`}>
                                {results && <OfferCardList data={siteData} key={uuid()} loading={false} userData={
                                    [
                                        {
                                            label: "age",
                                            value: props.age
                                        },
                                        {
                                            label: "zip code",
                                            value: props.zip_code
                                        }
                                    ]
                                }

                                    items={results.plans["ONE_SHARE"]}
                                />}
                            </TabPane>
                            <TabPane tab={`All ${results ? "(" + results.plans["ALL"].length + ")" : ""}`} key={`tab-${"3"}`}>
                                {results && <OfferCardList data={siteData} key={uuid()} loading={false} userData={
                                    [
                                        {
                                            label: "age",
                                            value: props.age
                                        },
                                        {
                                            label: "zip code",
                                            value: props.zip_code
                                        }
                                    ]
                                }

                                    items={results.plans["ALL"]}
                                />}
                            </TabPane>
                        </Tabs>
                    </Container>
                }
                <QuotesPanelProfile enabled={results} onPicwellOn={() => {

                    console.log("qpresults", results)

                    const tempR = { ...results };

                    tempR.plans.ALL = tempR.plans.ALL.map((res, index) => {
                        return { ...res, score: (100 / tempR.plans.ALL.length) * (index + 1) }
                    })

                    tempR.plans.MARKETPLACE = tempR.plans.MARKETPLACE.map((res, index) => {
                        return { ...res, score: (100 / tempR.plans.MARKETPLACE.length) * (index + 1) }
                    })
                    tempR.plans.NATIONAL_GENERAL = tempR.plans.NATIONAL_GENERAL.map((res, index) => {
                        return { ...res, score: (100 / tempR.plans.NATIONAL_GENERAL.length) * (index + 1) }
                    })
                    tempR.plans.ONE_SHARE = tempR.plans.ONE_SHARE.map((res, index) => {
                        return { ...res, score: (100 / tempR.plans.ONE_SHARE.length) * (index + 1) }
                    })

                    setResults(
                        tempR
                    )
                }

                }

                    onPicwellOff={() => {
                        const tempR = { ...results };

                        tempR.plans.ALL = tempR.plans.ALL.map((res, index) => {
                            return { ...res, score: null }
                        })

                        tempR.plans.MARKETPLACE = tempR.plans.MARKETPLACE.map((res, index) => {
                            return { ...res, score: null }
                        })
                        tempR.plans.NATIONAL_GENERAL = tempR.plans.NATIONAL_GENERAL.map((res, index) => {
                            return { ...res, score: null }
                        })
                        tempR.plans.ONE_SHARE = tempR.plans.ONE_SHARE.map((res, index) => {
                            return { ...res, score: null }
                        })

                        console.log("picwellOff", tempR)


                        setResults(
                            tempR
                        )
                    }}

                    data={siteData} mobileActiveContainer inputs={
                        <UserInputs {
                            ...state.params
                        } onSubmit={(newInputs) => {
                            setResults();
                            setError(false);
                            setNewState({ ...state, ...newInputs });

                            window.history.pushState(
                                "replace query string",
                                "quotes panel;",
                                `/quotes?${Object.keys(newInputs).reduce((oldValue, newValue) => oldValue + `${newValue}=${newInputs[newValue]}&`, "")}`
                            );
                        }} />
                    } />
            </Container>
            <Container id="pdf" />
            <Container className={css.mobileMenuContainer}>
                <Container className={css.mobileMenu}>
                    <Container onPress={() => setMobileActiveContainer("user-info")} className={`${css.mobileMenuOption} ${mobileActiveContainer == "user-info" ? css.mobileMenuOptionActive : ""}`}> <UserOutlined /> Profile</Container>
                    <Container onPress={() => setMobileActiveContainer("filters")} className={`${css.mobileMenuOption} ${mobileActiveContainer == "filters" ? css.mobileMenuOptionActive : ""}`}> <FilterOutlined /> Filters</Container>
                    <Container onPress={() => setMobileActiveContainer("results")} className={`${css.mobileMenuOption} ${mobileActiveContainer == "results" ? css.mobileMenuOptionActive : ""}`}><IdcardOutlined /> Results</Container>
                </Container>
            </Container>
        </>
    )
}

export {
    OfferCardList,
    SearchFilters
}